import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'
import { Helmet } from 'react-helmet'

export const AboutPageTemplate = ({
  title,
  content,
  contentComponent,
  helmet
}) => {
  const PageContent = contentComponent || Content

  return (
    <section className="about-container">
      {helmet || ''}
      <h2 className="about-title">
        {title}
      </h2>
      <PageContent className="content" content={content} />
    </section>
  )
}

AboutPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
  helmet: PropTypes.object
}

const AboutPage = ({ data }) => {
  const { markdownRemark: page } = data

  return (
    <Layout activePage="about">
      <AboutPageTemplate
        helmet={
          <Helmet titleTemplate="%s | Archive">
            <title>{'SM28 | About'}</title>
            {/* <meta
              name="description"
              content={'TODO: Meta description for About goes here'}
            /> */}
          </Helmet>
        }
        contentComponent={HTMLContent}
        title='About'
        content={page.html}
      />
    </Layout>
  )
}

AboutPage.propTypes = {
  data: PropTypes.object.isRequired
}

export default AboutPage

export const aboutPageQuery = graphql`
  query AboutPage {
    markdownRemark(frontmatter: { templateKey: { eq: "about" } }) {
      html
      frontmatter {
        title
      }
    }
  }
`
